import React from 'react';
import { Dialog } from '@mui/material';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Resizable } from 're-resizable';
import classNames from 'classnames';
import store from 'modules/shared/stores/store';
import { useSelector } from 'react-redux';
import {
    focusFloatingWindow,
    removeFloatingWindow,
    toggleFloatingWindowFullScreen,
    selectFloatingWindows,
    DEFAULT_WINDOW_SIZE,
} from 'modules/shared/stores/floating-windows-slice-handler';
import { lazyImportComponent } from '../../../utils/functions';

/**
 * Helper component given to MUI Dialog component.
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
function PaperComponent(props) {
    const boundsContainerDomSelector = props['aria-describedby'];

    if (boundsContainerDomSelector === undefined) {
        return (
            <Draggable handle=".wcf-ui-floating-window" cancel={'[class*="MuiButtonBase-root"]'}>
                <Paper {...props} />
            </Draggable>
        );
    }

    /*
     * Due to some reason, when giving the "bounds" option it is required to calculate positionOffset
     * from the DOM element identified by the given selector.
     * Therefore the querySelector is necessary.
     * NOTE: do not use query selectors else-where.
     */
    const boundsContainer = document.querySelector(boundsContainerDomSelector);

    return (
        <Draggable
            bounds={boundsContainerDomSelector}
            positionOffset={{ x: boundsContainer.offsetLeft, y: boundsContainer.offsetTop }}
            handle=".wcf-ui-floating-window"
            cancel={'[class*="MuiButtonBase-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

function FloatingWindowsRenderer() {
    const floatingWindows = useSelector(selectFloatingWindows);

    const toggleFullScreen = (id) => {
        store.dispatch(toggleFloatingWindowFullScreen(id));
    };

    const handleFocus = (id) => {
        store.dispatch(focusFloatingWindow(id));
    };

    const handleClose = (id) => {
        store.dispatch(removeFloatingWindow(id));
    };

    return (
        <>
            {Object.values(floatingWindows).map(
                /**
                 * @param {FloatingWindowOptions} floatingWindowOptions
                 * @return {JSX.Element}
                 */
                (floatingWindowOptions) => {
                    const floatingWindowClassNames = classNames('wcf-ui-floating-resizable-window', {
                        'full-screen': floatingWindowOptions.isFullScreen,
                        focused: floatingWindowOptions.focused,
                        'hidden': floatingWindowOptions.hidden,
                    });

                    let paperProps = {};

                    if (floatingWindowOptions.position.y) {
                        paperProps = { ...paperProps, sx: { top: `${floatingWindowOptions.position.y}px` } };
                    }

                    if (floatingWindowOptions.position.x) {
                        paperProps = { ...paperProps, sx: { left: `${floatingWindowOptions.position.x}px` } };
                    }

                    return (
                        <Dialog
                            open={true}
                            PaperComponent={PaperComponent}
                            PaperProps={paperProps}
                            className={floatingWindowClassNames}
                            // use these element to pass the boundsContainerDomSelector to PaperComponent, which, otherwise does not have access to the value
                            aria-describedby={floatingWindowOptions.boundsContainerDomSelector}
                            disableScrollLock
                            disableAutoFocus
                            disableEnforceFocus
                            hideBackdrop
                            key={floatingWindowOptions.id}
                        >
                            <Resizable
                                bounds="window"
                                defaultSize={{
                                    height: floatingWindowOptions.size.height,
                                    width: floatingWindowOptions.size.width,
                                }}
                                minHeight={DEFAULT_WINDOW_SIZE}
                                minWidth={DEFAULT_WINDOW_SIZE}
                                {...(floatingWindowOptions.isFullScreen && {
                                    enable: {
                                        top: false,
                                        right: false,
                                        bottom: false,
                                        left: false,
                                    },
                                    size: {
                                        height: '100vh',
                                        width: '100vw',
                                    },
                                })}
                            >
                                <div
                                    className="wcf-ui-floating-window"
                                    id={floatingWindowOptions.id}
                                    onMouseDown={() => handleFocus(floatingWindowOptions.id)}
                                >
                                    <div className={'top-bar'}>
                                        <span
                                            className="close-button stw-error"
                                            onClick={(e) => {
                                                !floatingWindowOptions.hideOnClose && handleClose(floatingWindowOptions.id);

                                                if (typeof floatingWindowOptions.closeCallback === 'function') {
                                                    floatingWindowOptions.closeCallback(floatingWindowOptions.id);
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className={'window-content'}>{lazyImportComponent(floatingWindowOptions)}</div>
                                    <div className={'footer-bar'}>
                                        <div
                                            className={'full-screen-button'}
                                            onClick={(e) => toggleFullScreen(floatingWindowOptions.id)}
                                        >
                                            <span
                                                className={
                                                    floatingWindowOptions.isFullScreen
                                                        ? 'stw-widget-weblink-in'
                                                        : 'stw-widget-weblink'
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Resizable>
                        </Dialog>
                    );
                },
            )}
        </>
    );
}
export default FloatingWindowsRenderer;
