import { createTheme } from '@mui/material/styles';
import LayoutManager from './layout-manager';

const cssVarToColor = (cssVar, alpha = null) => LayoutManager.getColorFromCssVar(cssVar, alpha);

/**
 * This class is used to customize the MUI theme according to skin rules.
 */
class MuiThemeCustomizer {
    static getInstance() {
        if (typeof MuiThemeCustomizer.instance !== 'undefined') {
            return MuiThemeCustomizer.instance;
        }

        MuiThemeCustomizer.instance = new MuiThemeCustomizer();

        return MuiThemeCustomizer.instance;
    }

    getCustomizedTheme() {
        return createTheme({
            palette: {
                primary: {
                    light: cssVarToColor('--COLOR_01'),
                    main: cssVarToColor('--COLOR_01'),
                    dark: cssVarToColor('--COLOR_02'),
                },
                secondary: {
                    light: cssVarToColor('--COLOR_01'),
                    main: cssVarToColor('--COLOR_01'),
                    dark: cssVarToColor('--COLOR_02'),
                },
                error: {
                    light: cssVarToColor('--COLOR_13'),
                    main: cssVarToColor('--COLOR_13'),
                    dark: cssVarToColor('--COLOR_GRADIENT_03_TOP'),
                },
                warning: {
                    light: cssVarToColor('--COLOR_GRADIENT_05_TOP'),
                    main: cssVarToColor('--COLOR_GRADIENT_05_TOP'),
                    dark: cssVarToColor('--COLOR_GRADIENT_03_BOTTOM'),
                },
                success: {
                    light: cssVarToColor('--COLOR_19'),
                    main: cssVarToColor('--COLOR_19'),
                    dark: cssVarToColor('--COLOR_18'),
                },
            },
            typography: {
                fontFamily: 'General-font, arial, verdana, sans-serif',
            },
            components: {
                MuiTypography: {
                    styleOverrides: {
                        root: {
                            fontSize: '14px',
                        },
                    },
                },
                MuiDialog: {
                    styleOverrides: {
                        paper: {
                            position: 'fixed',
                            margin: '0',
                            top: '85px',
                            minWidth: '400px',
                            borderRadius: '0',
                            maxWidth: 'fit-content',
                        },
                    },
                },
                MuiDialogActions: {
                    styleOverrides: {
                        root: {
                            justifyContent: 'space-between',
                            margin: '0 5px 0 5px',
                        },
                    },
                },
                MuiDialogTitle: {
                    styleOverrides: {
                        root: {
                            color: cssVarToColor('--COLOR_06'),
                            textTransform: 'uppercase',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            padding: '15px',
                        },
                    },
                },
                MuiDialogContentText: {
                    styleOverrides: {
                        root: {
                            color: cssVarToColor('--COLOR_06'),
                            fontSize: '14px',
                        },
                    },
                },
                MuiTooltip: {
                    styleOverrides: {
                        tooltip: {
                            fontSize: '12px',
                            backgroundColor: cssVarToColor('--COLOR_07'),
                        },
                        arrow: {
                            '&::before': {
                                backgroundColor: cssVarToColor('--COLOR_07'),
                            },
                        },
                    },
                },
                MuiButtonBase: {
                    defaultProps: {
                        disableRipple: true,
                    },
                    styleOverrides: {
                        root: ({ ownerState }) => {
                            // TimePicker buttons
                            if (ownerState.className.indexOf('MuiMultiSectionDigitalClockSection') >= 0) {
                                return {
                                    fontSize: '14px !important',
                                    width: '30px !important',
                                    height: '30px !important',
                                    borderRadius: '15px',
                                };
                            }

                            return '';
                        },
                    },
                },
                MuiButton: {
                    styleOverrides: {
                        root: ({ ownerState }) => {
                            const buttonsCss = {
                                fontSize: '14px',
                                textTransform: 'none',
                                height: '32px',
                                boxShadow: 'none',
                                '&:hover': {
                                    boxShadow: 'none',
                                },
                                '&:disabled': {},
                            };

                            if (typeof ownerState.role !== 'undefined') {
                                switch (ownerState.role) {
                                    case 'primary':
                                        buttonsCss.backgroundColor = cssVarToColor('--COLOR_01');
                                        buttonsCss['&:disabled'].backgroundColor = cssVarToColor('--COLOR_01');
                                        buttonsCss['&:disabled'].color = cssVarToColor('--COLOR_12');
                                        buttonsCss['&:disabled'].opacity = '.6';
                                        break;

                                    case 'cancel':
                                        buttonsCss.backgroundColor = cssVarToColor('--COLOR_10');
                                        buttonsCss.color = cssVarToColor('--COLOR_06');
                                        buttonsCss['&:hover'].backgroundColor = cssVarToColor('--COLOR_10');
                                        break;

                                    default:
                                        // nothing to do
                                        break;
                                }
                            }

                            return buttonsCss;
                        },
                    },
                },
                MuiCardHeader: {
                    styleOverrides: {
                        avatar: {
                            marginRight: 0,
                        },
                    },
                },
                MuiList: {
                    styleOverrides: {
                        root: {
                            '& .MuiButtonBase-root': {
                                fontSize: '14px',
                            },
                        },
                    },
                },
                MuiListItemIcon: {
                    styleOverrides: {
                        root: {
                            minWidth: 0,
                        },
                    },
                },
                MuiTabs: {
                    styleOverrides: {
                        root: {
                            '& .MuiButtonBase-root': {
                                fontSize: '14px',
                            },
                        },
                        scroller: {
                            height: '35px',
                        },
                    },
                },
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            width: '100%',
                            backgroundColor: cssVarToColor('--COLOR_12'),
                        },
                    },
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        root: {
                            '& fieldset': {
                                borderColor: cssVarToColor('--COLOR_10'),
                                borderRadius: '4px',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: cssVarToColor('--COLOR_10'),
                            },
                        },
                        input: {
                            fontSize: '13px',
                            padding: '6px 10px',
                            color: cssVarToColor('--COLOR_06'),
                            '&::placeholder': {
                                color: cssVarToColor('--COLOR_08'),
                                fontStyle: 'italic',
                            },
                            height: '20px',
                        },
                    },
                },
                MuiInputAdornment: {
                    styleOverrides: {
                        root: {
                            fontStyle: 'italic',
                            fontSize: '13px',
                            color: cssVarToColor('--COLOR_10'),
                        },
                    },
                },
                MuiIconButton: {
                    styleOverrides: {
                        root: {
                            color: cssVarToColor('--COLOR_01'),
                            fontSize: '15px',
                            marginRight: '-14px',
                        },
                    },
                },
                MuiAccordion: {
                    styleOverrides: {
                        root: {
                            boxShadow: 'none',
                        },
                    },
                },
                MuiAccordionDetails: {
                    styleOverrides: {
                        root: {
                            paddingTop: 0,
                        },
                    },
                },
                MuiAccordionSummary: {
                    styleOverrides: {
                        root: {
                            justifyContent: 'flex-start',
                        },
                        content: {
                            flexGrow: 0,
                            margin: '0 5px 0 5px !important',
                        },
                    },
                },
                MuiPickersDay: {
                    styleOverrides: {
                        root: {
                            fontSize: '14px',
                            width: '30px',
                            height: '30px',
                            margin: '0',
                            '&.Mui-selected': {
                                backgroundColor: cssVarToColor('--COLOR_01'),
                            },
                        },
                        today: {
                            borderColor: `${cssVarToColor('--COLOR_01')} !important`,
                        },
                    },
                },
                MuiDateCalendar: {
                    styleOverrides: {
                        root: {
                            width: '250px',
                            height: '290px',
                        },
                    },
                },
                MuiMultiSectionDigitalClock: {
                    styleOverrides: {
                        root: {
                            maxHeight: '290px',
                        },
                    },
                },
                MuiDayCalendar: {
                    styleOverrides: {
                        weekDayLabel: {
                            fontSize: '14px',
                            fontWeight: 'bold',
                            width: '30px',
                            color: cssVarToColor('--COLOR_06'),
                        },
                    },
                },
                MuiPickersCalendarHeader: {
                    styleOverrides: {
                        root: {
                            backgroundColor: cssVarToColor('--COLOR_01'),
                            marginTop: '0',
                        },
                        labelContainer: {
                            fontSize: '14px',
                            color: cssVarToColor('--COLOR_12'),
                            marginLeft: 'auto',
                            paddingLeft: '25px',
                        },
                        switchViewButton: {
                            display: 'none',
                        },
                    },
                },
                MuiPickersArrowSwitcher: {
                    styleOverrides: {
                        button: {
                            color: cssVarToColor('--COLOR_12'),
                            fontSize: '25px',
                            '&:first-of-type': {
                                position: 'absolute',
                                left: '0',
                            },
                        },
                    },
                },
                MuiAlert: {
                    styleOverrides: {
                        root: {
                            fontSize: '14px',
                            color: cssVarToColor('--COLOR_07'),
                            whiteSpace: 'pre-line',
                        },
                        filled: {
                            padding: '0',
                        },
                        filledInfo: {
                            backgroundColor: cssVarToColor('--COLOR_04'),
                        },
                        filledWarning: {
                            backgroundColor: cssVarToColor('--COLOR_24'),
                        },
                        filledError: {
                            backgroundColor: cssVarToColor('--COLOR_17'),
                        },
                        action: {
                            padding: '0',
                            margin: '0',
                        },
                        message: {
                            padding: '8px',
                            maxWidth: '500px',
                            wordBreak: 'break-all',
                        },
                    },
                },
                MuiSelect: {
                    styleOverrides: {
                        select: {
                            fontSize: '14px',
                            minHeight: 'auto',
                        },
                    },
                },
                MuiInput: {
                    styleOverrides: {
                        root: {
                            '&:before': {
                                borderBottom: 'none',
                                transition: 'none',
                            },
                            '&:after': {
                                borderBottom: 'none',
                                transition: 'none',
                            },
                            '&:hover:not(.Mui-disabled, .Mui-error):before': {
                                borderBottom: `1px solid ${cssVarToColor('--COLOR_09')}`,
                            },
                        },
                    },
                },
                MuiSlider: {
                    styleOverrides: {
                        root: {
                            height: '2px',
                        },
                        thumb: {
                            width: '12px',
                            height: '12px',
                            backgroundColor: cssVarToColor('--COLOR_11'),
                            border: `1px solid ${cssVarToColor('--COLOR_01')}`,
                        },
                    },
                },
            },
        });
    }
}

export default MuiThemeCustomizer.getInstance();
