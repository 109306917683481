import { configureStore } from '@reduxjs/toolkit';
import Log from 'utils/log';
import { toastersSliceHandler } from 'modules/shared/stores/toasters-slice-handler';
import { contactsSliceHandler } from 'modules/contacts/stores/contacts-slice-handler';
import { settingsSliceHandler } from 'modules/settings/stores/settings-slice-handler';
import { meetingRoomsSliceHandler } from 'modules/meeting-rooms/stores/meeting-rooms-slice-handler';
import { userGroupsSliceHandler } from 'modules/contacts/stores/user-groups-slice-handler';
import { videoStreamingSliceHandler } from 'modules/video-popups/stores/video-streaming-slice-handler';
import { portalsSliceHandler } from './portals-slice-handler';
import { floatingWindowsSliceHandler } from './floating-windows-slice-handler';

const reducer = {};
const sliceHandlers = [
    toastersSliceHandler,
    contactsSliceHandler,
    settingsSliceHandler,
    meetingRoomsSliceHandler,
    portalsSliceHandler,
    userGroupsSliceHandler,
    floatingWindowsSliceHandler,
    videoStreamingSliceHandler,
];

// set up the reducers
sliceHandlers.forEach((handler) => {
    const handlerKey = handler.constructor.name.replace('Handler', '');
    reducer[handlerKey[0].toLowerCase() + handlerKey.substr(1)] = handler.getReducer();
});
// build up the store
const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});
// propagate the store in handlers
sliceHandlers.forEach((handler) => {
    handler.setStore(store);
});

Log.debug('STORE', `App store has been set with ${sliceHandlers.length} slices: ${Object.keys(reducer).join(',')}`);

export default store;
