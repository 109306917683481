import { createSlice } from '@reduxjs/toolkit';

import AbstractSliceHandler from 'modules/shared/stores/abstract-slice-handler';
import LocalEvent from 'utils/local-event';
import Log from 'utils/log';
import Queue from 'utils/queue';
import sdkClient from '../../../utils/sdk-client';

export const MeetingRoomType = {
    PUBLIC: 'public',
    ORGANIZATION: 'organization',
    ORGANIZATION_AND_GUESTS: 'organization-and-guests',
    SECURED: 'secured',
};

export const MeetingRoomShareType = {
    CONTACTS: 'contacts',
    CONVERSATIONS: 'conversations',
    EMAIL: 'email',
};

const initialState = {
    dataIsLoading: true,
    dataIsUpdating: false,
    searchTerm: '',
    roomToDelete: null,
    roomToEdit: null,
    roomToShare: null,
    sdkError: null,
    meetingRooms: {
        /**
         * @type MeetingRoom[]
         */
        created: [],
        /**
         * @type MeetingRoom[]
         */
        recent: [],
    },
    searchResults: {
        created: [],
        recent: [],
    },
    allowVirality: null,
};

class MeetingRoomsSliceHandler extends AbstractSliceHandler {
    static getInstance() {
        if (!MeetingRoomsSliceHandler.instance) {
            MeetingRoomsSliceHandler.instance = new MeetingRoomsSliceHandler();
        }

        return MeetingRoomsSliceHandler.instance;
    }

    constructor() {
        super('meetingRoomsSlice');

        LocalEvent.setListener(LocalEvent.eventTypes.SDK_CLIENT_AUTHENTICATED, (event) => {
            this.onSdkClientAuthenticated(event);
        });

        this.searchDebouncer = null;

        this.slice = createSlice({
            name: this.sliceName,
            initialState,
            reducers: {
                toggleLoading(state, action) {
                    state.dataIsLoading = action.payload;
                },
                toggleDataUpdating(state, action) {
                    state.dataIsUpdating = action.payload;
                },
                setMeetingRooms(state, action) {
                    state.meetingRooms = action.payload;
                },
                setSearchResults(state, action) {
                    state.searchResults = action.payload;
                },
                setSearchTerm(state, action) {
                    state.searchTerm = action.payload;
                },
                setRoomToEdit(state, action) {
                    state.roomToEdit = action.payload;
                },
                setRoomToShare(state, action) {
                    state.roomToShare = action.payload;
                },
                setRoomToDelete(state, action) {
                    state.roomToDelete = action.payload;
                },
                addCreatedRoom(state, action) {
                    state.meetingRooms.created = [action.payload, ...state.meetingRooms.created];
                },
                removeDeletedRoom(state, action) {
                    state.meetingRooms.created = state.meetingRooms.created.filter(
                        (room) => room.uuid !== action.payload,
                    );
                    // remove the rooms from the search results as well (if is there)
                    state.searchResults.created = state.searchResults.created.filter(
                        (room) => room.uuid !== action.payload,
                    );
                },
                saveEditedRoom(state, action) {
                    const editedRoom = action.payload;
                    const roomIndex = state.meetingRooms.created.findIndex((room) => room.id === editedRoom.id);
                    if (roomIndex >= 0) {
                        state.meetingRooms.created[roomIndex] = {
                            ...state.meetingRooms.created[roomIndex],
                            ...editedRoom,
                        };
                    }
                },
                shareRoom(state, action) {
                    console.log(`Sharing room with id: ${action.payload}`);
                },
                setAllowVirality(state, action) {
                    state.allowVirality = action.payload;
                },
            },
        });
    }

    /**
     * Create a meeting room.
     * @param {MeetingRoomCreate} roomToCreate
     */
    createRoom = (roomToCreate) => {
        // this is a special case when the webchat has to create a video conference instead
        if (roomToCreate.type === MeetingRoomType.SECURED) {
            LocalEvent.trigger(LocalEvent.eventTypes.MEETING_ROOM_SECURE_CREATED, roomToCreate);

            return;
        }

        this.dispatch('toggleDataUpdating', true);

        webchatSDK.STWMeetingRoomManager.createMeetingRoom(roomToCreate)
            .then(
                /**
                 * SDK resolves with the created room.
                 * @param {SdkMeetingRoom} rawCreatedMeetingRoom
                 */
                (rawCreatedMeetingRoom) => {
                    // the public url is not in the original payload. must make a get action to have it
                    webchatSDK.STWMeetingRoomManager.getMeetingRoom(rawCreatedMeetingRoom.meetingRoomUuid).then(
                        (createdMeetingRoom) => {
                            this.dispatchMultiple(
                                this.action('addCreatedRoom', createdMeetingRoom.toJSON()),
                                this.action('toggleDataUpdating', false),
                            );
                        },
                    );
                },
            )
            .catch((sdkError) => {
                this.dispatch('toggleDataUpdating', false);
                this.treatSdkError('createRoom', sdkError);
            });
    };

    doDeleteRoom = () => {
        this.dispatch('toggleDataUpdating', true);

        const deletedRoom = new webchatSDK.MeetingRoom(this.getOwnState().roomToDelete);
        this.dispatch('setRoomToDelete', null);

        webchatSDK.STWMeetingRoomManager.deleteMeetingRooms([deletedRoom])
            .then(() => {
                // set in the store the new data
                this.dispatchMultiple(
                    this.action('removeDeletedRoom', deletedRoom.meetingRoomUuid),
                    this.action('toggleDataUpdating', false),
                );
            })
            .catch((sdkError) => {
                this.dispatch('toggleDataUpdating', false);
                this.treatSdkError('doDeleteRoom', sdkError);
            });
    };

    doEditRoom = () => {
        this.dispatch('toggleDataUpdating', true);

        const editedRoom = new webchatSDK.MeetingRoom(this.getOwnState().roomToEdit);
        this.dispatch('setRoomToEdit', null);

        webchatSDK.STWMeetingRoomManager.updateMeetingRoom(editedRoom)
            .then(() => {
                // set in the store the new data
                this.dispatchMultiple(
                    this.action('saveEditedRoom', editedRoom.toJSON()),
                    this.action('toggleDataUpdating', false),
                );
            })
            .catch((sdkError) => {
                this.dispatch('toggleDataUpdating', false);
                this.treatSdkError('doEditRoom', sdkError);
            });
    };

    /**
     * Execute the share action for the meeting room which was chosen to be shared.
     * @param {MeetingRoomShareOptions} options
     */
    doShareRoom = (options) => {
        const shareRoomData = {
            meetingRoom: this.getOwnState().roomToShare,
            ...options,
        };

        LocalEvent.trigger(LocalEvent.eventTypes.MEETING_ROOM_SHARED, shareRoomData);

        this.dispatch('setRoomToShare', null);
    };

    shareRoomFromShortcutsMenu = (options) => {
        const shareRoomData = {
            ...options,
            shareType: 'shortcuts-menu',
        };

        LocalEvent.trigger(LocalEvent.eventTypes.MEETING_ROOM_SHARED, shareRoomData);
    };

    updateSearchTerm = (val) => {
        if (this.searchDebouncer !== null) {
            clearTimeout(this.searchDebouncer);
        }

        this.dispatch('setSearchTerm', val);

        this.searchDebouncer = setTimeout(() => {
            this.searchDebouncer = null;

            const searchResults = { created: [], recent: [] };

            this.dispatchMultiple(
                this.action('toggleDataUpdating', true),
                this.action('setSearchResults', { ...searchResults }),
            );

            const ownState = this.getOwnState();

            searchResults.created = ownState.meetingRooms.created.filter((meetingRoom) =>
                meetingRoom.label.toLowerCase().includes(ownState.searchTerm.toLowerCase()),
            );

            searchResults.recent = ownState.meetingRooms.recent.filter((meetingRoom) =>
                meetingRoom.label.toLowerCase().includes(ownState.searchTerm.toLowerCase()),
            );

            setTimeout(() => {
                this.dispatchMultiple(
                    this.action('setSearchResults', { ...searchResults }),
                    this.action('toggleDataUpdating', false),
                );
            });
        }, 100);
    };

    loadMeetingRooms = () =>
        new Promise((resolve) => {
            // cannot use Promise.all because if one fails , both are treated as failed
            const meetingRooms = {
                created: [],
                recent: [],
            };

            const queue = new Queue(() => {
                this.dispatchMultiple(
                    this.action('setMeetingRooms', meetingRooms),
                    this.action('toggleLoading', false),
                );

                resolve();
            });

            queue.add(() => {
                webchatSDK.STWMeetingRoomManager.getMeetingRoomList()
                    .then((meetingRoomsLists) => {
                        meetingRoomsLists.forEach((meetingRoom) => {
                            meetingRooms.created.push(meetingRoom.toJSON());
                        });

                        queue.advance();
                    })
                    .catch((err) => {
                        Log.error(
                            'MEETING ROOMS STORE',
                            `There was an error while loading meeting rooms: ${JSON.stringify(err)}`,
                        );

                        queue.advance();
                    });
            });

            queue.add(() => {
                webchatSDK.STWMeetingRoomManager.getRecentMeetingRoomList()
                    .then((recentMeetingRoomsLists) => {
                        recentMeetingRoomsLists.forEach((meetingRoom) => {
                            meetingRooms.recent.push(meetingRoom.toJSON());
                        });

                        queue.advance();
                    })
                    .catch((err) => {
                        Log.error(
                            'MEETING ROOMS STORE',
                            `There was an error while loading recent meeting rooms: ${JSON.stringify(err)}`,
                        );

                        queue.advance();
                    });
            });

            queue.run();
        });

    onSdkClientAuthenticated = (event) => {
        // load the initial CUG data
        this.getCugSettings(event);

        // listen for further CUG updates
        sdkClient.setSocketEventListener('ORGANIZATION_DATA_UPDATE', (cugData) => this.parseCugData(cugData));
    };

    getCugSettings = () => {
        sdkClient.getCugSettings().then((cugSettings) => {
            this.dispatch('setAllowVirality', cugSettings.AllowVirality);
        });
    };

    parseCugData = (cugData) => {
        
        if (typeof cugData.CugSettingsRare === 'undefined') {
            return;
        }
        
        this.dispatch('setAllowVirality', cugData.CugSettingsRare.AllowVirality);
    };
}

// the handler
export const meetingRoomsSliceHandler = MeetingRoomsSliceHandler.getInstance();

// the selectors to be used in useSelect
export const selectDataIsLoading = (state) => state.meetingRoomsSlice.dataIsLoading;
export const selectSearchTerm = (state) => state.meetingRoomsSlice.searchTerm;
export const selectDataIsUpdating = (state) => state.meetingRoomsSlice.dataIsUpdating;
export const selectRoomToDelete = (state) => state.meetingRoomsSlice.roomToDelete;
export const selectRoomToEdit = (state) => state.meetingRoomsSlice.roomToEdit;
export const selectRoomToShare = (state) => state.meetingRoomsSlice.roomToShare;
export const selectAllowVirality = (state) => state.meetingRoomsSlice.allowVirality; // Allow users to invite guests

export const selectCreatedMeetingRooms = (state) =>
    state.meetingRoomsSlice.searchTerm.length > 0
        ? state.meetingRoomsSlice.searchResults.created
        : state.meetingRoomsSlice.meetingRooms.created;
export const selectRecentMeetingRooms = (state) =>
    state.meetingRoomsSlice.searchTerm.length > 0
        ? state.meetingRoomsSlice.searchResults.recent
        : state.meetingRoomsSlice.meetingRooms.recent;

// other named exports
export const { setRoomToDelete, setRoomToEdit, setRoomToShare } = meetingRoomsSliceHandler.slice.actions;
export const {
    createRoom,
    doDeleteRoom,
    doEditRoom,
    doShareRoom,
    updateSearchTerm,
    shareRoomFromShortcutsMenu,
    loadMeetingRooms,
} = meetingRoomsSliceHandler;
