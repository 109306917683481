/**
 * Some very simple class used to deal with cookies (taken from W3schools).
 * No need external library for this.
 */
export default class CookiesUtil {
    /**
     * Get the value of a cookie.
     * @param {string} cookieName
     * @param {any} defaultValue What to return if cookie was not found.
     * @return string|null
     */
    static getCookie(cookieName, defaultValue = null) {
        const name = `${cookieName}=`;
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }

        return defaultValue;
    }

    /**
     * Set a cookie.
     * @param {string} cookieName
     * @param {string} cookieValue
     * @param {number} expireDays The lifetime of cookie, in days. By default, will expire in a long time, 1 year.
     * @return void
     */
    static setCookie(cookieName, cookieValue, expireDays = 365) {
        const d = new Date();
        d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
        const expires = `expires=${d.toUTCString()}`;
        document.cookie = `${cookieName}=${cookieValue};${expires};path=/;samesite=lax`;
    }

    /**
     * Delete a cookie.
     * @param {string} cookieName
     * @return boolean
     */
    static deleteCookie(cookieName) {
        CookiesUtil.setCookie(cookieName, '', -1);
    }
}
