import { createSlice } from '@reduxjs/toolkit';
import AbstractSliceHandler from 'modules/shared/stores/abstract-slice-handler';
import { randUuid } from 'utils/functions';
import Log from 'utils/log';

export const DEFAULT_WINDOW_SIZE = 150;
const initialState = {
    /**
     * The list
     * @type [key: string]: FloatingWindowOptions
     */
    windows: {},
    /**
     * The id of the last floating window.
     */
    lastAddedWindowId: null,
};

class FloatingWindowsSliceHandler extends AbstractSliceHandler {
    static getInstance() {
        if (!FloatingWindowsSliceHandler.instance) {
            FloatingWindowsSliceHandler.instance = new FloatingWindowsSliceHandler();
        }

        return FloatingWindowsSliceHandler.instance;
    }

    constructor() {
        super('floatingWindowsSlice');

        this.slice = createSlice({
            name: this.sliceName,
            initialState,
            reducers: {
                /**
                 * Create a floating window.
                 * @param {object} state
                 * @param {{payload: FloatingWindowOptions}} action
                 */
                addFloatingWindow(state, action) {
                    /**
                     * @type FloatingWindowOptions
                     */
                    const defaultOptions = {
                        focused: false,
                        isFullScreen: false,
                        position: {
                            x: undefined,
                            y: undefined,
                        },
                        size: {
                            height: DEFAULT_WINDOW_SIZE,
                            width: DEFAULT_WINDOW_SIZE,
                        },
                        hidden: false,
                    };

                    const lastAddedWindowId = `floating-window-${randUuid()}`;

                    state.windows = {
                        ...state.windows,
                        [lastAddedWindowId]: { id: lastAddedWindowId, ...defaultOptions, ...action.payload },
                    };

                    state.lastAddedWindowId = lastAddedWindowId;

                    Log.debug(
                        'FLOATING WINDOWS STORE',
                        `Registering floating window for component ${
                            action.payload.componentPath ?? 'unspecified'
                        } with id ${lastAddedWindowId}`,
                    );
                },
                /**
                 * Focus on a floating window to bring it on top of the others.
                 * @param {object} state
                 * @param {{payload: FloatingWindowOptions}} action
                 */
                focusFloatingWindow(state, action) {
                    // check if the window is already focused and do not force re-render
                    const id = action.payload;
                    const currentState = state.windows[id];

                    if (currentState.focused) {
                        return;
                    }

                    state.windows = Object.values(state.windows).reduce(
                        (acc, currentItem) => ({
                            ...acc,
                            [currentItem.id]: {
                                ...state.windows[currentItem.id],
                                focused: currentItem.id === action.payload,
                            },
                        }),
                        {},
                    );
                },
                toggleFloatingWindowFullScreen(state, action) {
                    const id = action.payload;

                    const currentState = state.windows[id];

                    // save the actual size before full screen
                    if (!state.windows[id].isFullScreen) {
                        currentState.lastSize = { ...currentState.size };
                    }

                    state.windows = {
                        ...state.windows,
                        [id]: {
                            ...state.windows[id],
                            isFullScreen: !state.windows[id].isFullScreen,
                            size: {
                                height: state.windows[id].isFullScreen
                                    ? currentState.lastSize.height
                                    : window.innerHeight,
                                width: state.windows[id].isFullScreen ? currentState.lastSize.width : window.innerWidth,
                            },
                        },
                    };
                },
                toggleFloatingWindowVisibilityState(state, action) {
                    const id = action.payload;
                    const currentState = state.windows[id];
                    state.windows = {
                        ...state.windows,
                        [id]: {
                            ...state.windows[id],
                            hidden: !currentState.hidden,
                        },
                    };
                },
                /**
                 * Remove a registered floating window.
                 * @param {object} state
                 * @param {{payload: number}} action
                 */
                removeFloatingWindow(state, action) {
                    const id = action.payload;

                    if (typeof state.windows[id] === 'undefined') {
                        Log.notice(
                            'FLOATING WINDOWS STORE',
                            `The floating window with id ${id} cannot be closed because does not exists.`,
                        );

                        return;
                    }

                    const currentWindows = { ...state.windows };
                    delete currentWindows[id];

                    state.windows = currentWindows;
                },
                setLastAddedWindowId(state, action) {
                    state.lastAddedWindowId = action.payload;
                },
            },
        });
    }

    /**
     * Open a floating window.
     * @param {FloatingWindowOptions} options
     */
    openFloatingWindow = (options) => {
        const ownState = this.getOwnState().windows;

        if (
            typeof options.floatingWindowId !== 'undefined' &&
            options.floatingWindowId !== null &&
            typeof ownState[options.floatingWindowId] !== 'undefined'
        ) {
            Log.debug('FLOATING WINDOWS STORE', `Focusing floating window with id ${options.floatingWindowId}.`);

            this.dispatchMultiple(
                this.action('focusFloatingWindow', options.floatingWindowId),
                this.action('setLastAddedWindowId', options.floatingWindowId),
            );
        } else {
            this.dispatch('addFloatingWindow', options);
        }
    };

    closeFloatingWindow = (floatingWindowId) => {
        setTimeout(() => {
            this.dispatch('removeFloatingWindow', floatingWindowId);
        });
    };

    toggleFloatingWindowVisibility = (floatingWindowId) => {
        this.dispatch('toggleFloatingWindowVisibilityState', floatingWindowId);
    }
}

// the handler
export const floatingWindowsSliceHandler = FloatingWindowsSliceHandler.getInstance();
export const selectFloatingWindows = (state) => state.floatingWindowsSlice.windows;
export const { addFloatingWindow, focusFloatingWindow, removeFloatingWindow, toggleFloatingWindowFullScreen } =
    floatingWindowsSliceHandler.slice.actions;
export const getLastAddedFloatingWindowId = () =>
    floatingWindowsSliceHandler.store.getState().floatingWindowsSlice.lastAddedWindowId;
export const { closeFloatingWindow, openFloatingWindow, toggleFloatingWindowVisibility } = floatingWindowsSliceHandler;
